import qs from 'qs'
export default function FinancialSys(ajax, config) {
  const { domainName, FinancialSys } = config
  return {
    // 基础数据配置表查询接口
    basicData: {
      // 查询付款方式列表
      payModeList: (opt) =>
        ajax({
          url: '/basic-data/pay-mode/list',
          method: 'POST',
          ...opt,
        }),
      // 查询收款方式列表
      receiptModeList: (opt) =>
        ajax({
          url: '/basic-data/receipt-mode/list',
          method: 'POST',
          ...opt,
        }),
      // 根据公司查询所有的收款银行信息
      receiptModeBank: (opt) =>
        ajax({
          url: '/basic-data/receipt-mode/bank',
          method: 'POST',
          ...opt,
        }),
      // 查询币种列表
      currencyCodeList: (opt) =>
        ajax({
          url: '/basic-data/currency/simple-list',
          method: 'GET',
          ...opt,
        }),
      // 查收款方式-订单详情手工录入单使用
      receiptModeOrderList: (opt) =>
        ajax({
          url: '/basic-data/receipt-modes',
          method: 'POST',
          ...opt,
        }),
      // 查收款方式-财务系统使用
      receiptModesCodes: (opt) =>
        ajax({
          url: '/basic-data/receipt-modes-codes',
          method: 'POST',
          ...opt,
        }),
      // 查收款方式-订单系统使用
      orderReceiptModesCodes: (opt) =>
        ajax({
          url: '/receipt-by-order/receipt-modes',
          method: 'POST',
          ...opt,
        }),
      // 预收款查询收款方式专用接口
      receiptModesPreReceipt: (opt) =>
        ajax({
          url: '/basic-data/receipt-modes-pre-receipt',
          method: 'POST',
          ...opt,
        }),
      // 查收款银行列表-订单详情手工录入单使用
      receiptModeOrderAccountlist: (opt) =>
        ajax({
          url: '/basic-data/bank-info',
          method: 'POST',
          ...opt,
        }),
      // 查询成本项列表-付款项目枚举
      costItemList: (opt) =>
        ajax({
          url: '/basic-data/cost-item/list',
          method: 'POST',
          ...opt,
          data: {
            ...opt.data,
            size: 1000,
          },
        }),
      // 查询成本项列表(按业财资源类型汇总数据)
      costItemListByResourceType: (opt) =>
        ajax({
          url: '/basic-data/cost-item-by-resource-type/list',
          method: 'POST',
          ...opt,
        }),

      // 查询三级渠道对应的外部合作类目
      categoryInfo: (opt) =>
        ajax({
          url: '/basic-data/category-info',
          method: 'POST',
          ...opt,
        }),
      // 查询收款单可用的发票项目列表
      invoiceItemEnums: (opt) =>
        ajax({
          url: '/basic-data/invoice-item-config/list/by/',
          method: 'POST',
          ...opt,
        }),
      getExchangeRate: (opt) =>
        ajax({
          url: '/basic-data/e-rate/list',
          method: 'GET',
          ...opt,
        }),
      // 查询审核人员
      auditUserList: (opt) =>
        ajax({
          url: '/basic-data/audit-user-list',
          method: 'GET',
          ...opt,
        }),
      // 查询银行信息
      getReceiptAccountList: (opt) =>
        ajax({
          url: '/receipt-by-order/receipt-account-list',
          method: 'post',
          ...opt,
        }),
    },
    // 水单查询接口
    flowList: (opt) =>
      ajax({
        url: '/flow/list',
        method: 'POST',
        ...opt,
      }),
    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 结算
    settlement: {
      //作废批次
      cancelBatch: (opt) =>
        ajax({
          url: '/settlement/batch/cancel',
          method: 'POST',
          ...opt,
        }),
      companyCarryOver: (opt) =>
        ajax({
          url: '/settlement/company/carry-over',
          method: 'POST',
          ...opt,
        }),
      // 结算单列表
      list: (opt) =>
        ajax({
          url: '/settlement/list',
          method: 'GET',
          ...opt,
        }),
      // 审核
      audit: (opt) =>
        ajax({
          url: '/settlement/audit',
          method: 'POST',
          ...opt,
        }),
      // 结转
      CarryOver: (opt) =>
        ajax({
          url: '/settlement/company/carry-over',
          method: 'POST',
          ...opt,
        }),
      // 删除
      delete: (opt) =>
        ajax({
          url: '/settlement/delete',
          method: 'POST',
          ...opt,
        }),
      // 退回
      rollback: (opt) =>
        ajax({
          url: '/settlement/rollback',
          method: 'POST',
          ...opt,
        }),
      // 作废
      invalid: (opt) =>
        ajax({
          url: '/settlement/invalid',
          method: 'POST',
          ...opt,
        }),
      // 关联交易-作废
      relatedCancel: (opt) =>
        ajax({
          url: '/settlement/related/cancel',
          method: 'POST',
          ...opt,
        }),
      // 确认
      confirm: (opt) =>
        ajax({
          url: '/settlement/confirm',
          method: 'POST',
          ...opt,
        }),
      // 结算单详情-确认
      relatedConfirm: (opt) =>
        ajax({
          url: '/settlement/related/confirm',
          method: 'POST',
          ...opt,
        }),
      // 结算单详情
      details: (opt) =>
        ajax({
          url: '/settlement/detail',
          method: 'GET',
          ...opt,
        }),
      // 关联交易-获取结算单表头详情信息
      relatedDetailQuery: (opt) =>
        ajax({
          url: '/settlement/related/detail/query',
          method: 'GET',
          ...opt,
        }),
      // 关联交易-获取结算单详情信息
      relatedItemListQuery: (opt) =>
        ajax({
          url: '/settlement/related/item-list/query',
          method: 'GET',
          ...opt,
        }),
      // 关联交易-收入&结转明细
      companySettlementListQuery: (opt) =>
        ajax({
          url: '/settlement/related/company-settlement-list/query',
          method: 'GET',
          ...opt,
        }),
      // 关联交易-结转详情-关联交易明细
      relatedCompanySettlementListQuery: (opt) =>
        ajax({
          url: '/settlement/related/company-item-list/query',
          method: 'GET',
          ...opt,
        }),
      // 公司结算单列表
      payOrderList: (opt) =>
        ajax({
          url: '/settlement/payment-order-list',
          method: 'GET',
          ...opt,
        }),
      // 结转单列表
      companyList: (opt) =>
        ajax({
          url: '/settlement/company-list',
          method: 'GET',
          ...opt,
        }),
      // 公司结算单详情
      companyDetails: (opt) => {
        const { code, ...rest } = opt
        return ajax({
          url: `/settlement/company-detail/${code}`,
          method: 'GET',
          ...rest,
        })
      },
      // 订单结算单列表
      orderList: (opt) =>
        ajax({
          url: '/settlement/order-list',
          method: 'GET',
          ...opt,
        }),
      // 订单结算单列表
      orderDetails: (opt) =>
        ajax({
          url: '/settlement/order-detail',
          method: 'GET',
          ...opt,
        }),
      // 查询结算单佣金列表
      commissionList: (opt) =>
        ajax({
          url: '/settlement/commission-list',
          method: 'GET',
          ...opt,
        }),
      // 关联结算start
      releSettlement: {
        //未结束列表 查询未进行关联结算明细数据
        list: (opt) =>
          ajax({
            url: '/settlement/related/not-settlement-team-list',
            method: 'GET',
            ...opt,
          }),
        //批量结算 生成关联交易结算单
        create: (opt) =>
          ajax({
            url: '/settlement/related/create',
            method: 'POST',
            isResponse: true,
            ...opt,
          }),
        //结算处理列表 查询关联交易结算单列表
        query: (opt) =>
          ajax({
            url: '/settlement/related/list/query',
            method: 'GET',
            ...opt,
          }),
        carryOver: (opt) =>
          ajax({
            url: '/settlement/related/company-settlement-list/query',
            method: 'GET',
            ...opt,
          }),
      },
      // 关联结算end
      /******* 关联交易结算价规则 Start *******/
      ruleRelated: {
        // 根据参数查询关联交易结算价规则列表
        list: (opt) =>
          ajax({
            url: '/settlement/rule/related/query-list',
            method: 'GET',
            ...opt,
          }),
        // 创建
        add: (opt) =>
          ajax({
            url: '/settlement/rule/related/add',
            method: 'POST',
            ...opt,
          }),
        // 禁用
        disable: (opt) =>
          ajax({
            url: '/settlement/rule/related/disable',
            method: 'POST',
            ...opt,
          }),
        // 编辑
        edit: (opt) =>
          ajax({
            url: '/settlement/rule/related/edit',
            method: 'POST',
            ...opt,
          }),
        // 启用
        enable: (opt) =>
          ajax({
            url: '/settlement/rule/related/enable',
            method: 'POST',
            ...opt,
          }),

        // 关联交易 付款列表
        paymentList: (opt) =>
          ajax({
            url: '/settlement/company-list/pay',
            method: 'GET',
            ...opt,
          }),
        receptList: (opt) =>
          ajax({
            url: '/settlement/related/recept-list',
            method: 'GET',
            ...opt,
          }),
      },
      /******* 关联交易结算价规则 End *******/
    },
    // 毛利审批
    grossProfit: {
      // 毛利审批申请列表
      batchPage: (opt) =>
        ajax({
          url: '/settlement/batch-page',
          method: 'GET',
          ...opt,
        }),
      pendingBatchPage: (opt) =>
        ajax({
          url: '/settlement/batch-page/my-pending-audit',
          method: 'GET',
          ...opt,
        }),
      // 我的已审批批次列表
      myAudited: (opt) =>
        ajax({
          url: '/settlement/batch-page/my-audited',
          method: 'GET',
          ...opt,
        }),
      // 批次详情
      batchDetail: (opt) =>
        ajax({
          url: `/settlement/batch-detail/${opt.batchNo}`,
          method: 'GET',
          ...opt,
        }),
      // 提交批次审核
      submitAudit: (opt) =>
        ajax({
          url: '/settlement/batch/submit-audit',
          method: 'POST',
          ...opt,
        }),
      // 任务审批(审批驳回/同意)
      audit: (opt) =>
        ajax({
          url: '/bpm/task/audit',
          method: 'POST',
          ...opt,
        }),
      // 审批记录
      taskList: (opt) =>
        ajax({
          url: '/bpm/task/list',
          method: 'GET',
          ...opt,
        }),
      // 毛利审批详情-订单明细
      orderList: (opt) =>
        ajax({
          url: '/settlement/batch/order-list',
          method: 'GET',
          ...opt,
        }),
      // 毛利审批详情-确认单明细
      payOrderList: (opt) =>
        ajax({
          url: '/settlement/batch/payment-order-list',
          method: 'GET',
          ...opt,
        }),
      // 毛利审批详情-收入成本结转明细
      companyList: (opt) =>
        ajax({
          url: '/settlement/batch/company-list',
          method: 'GET',
          ...opt,
        }),
      // 毛利审批详情-旧批次号映射
      mappingBatchNo: (opt) =>
        ajax({
          url: `/settlement/batch/mapping/${opt.batchNo}`,
          method: 'GET',
          ...opt,
        }),
    },
    // 对账
    statement: {
      // 按成本项拆分付款单
      classifyItem: (opt) =>
        ajax({
          url: '/sub-payment/classify-item',
          method: 'POST',
          ...opt,
        }),
      // 确认
      confirm: (opt) =>
        ajax({
          url: '/sub-payment/confirm',
          method: 'POST',
          ...opt,
        }),
      // 付款申请页，详情计算专用接口
      detailItemDetail: (opt) =>
        ajax({
          url: '/sub-payment/list-detail',
          method: 'POST',
          ...opt,
        }),
      // 批量付款申请校验接口
      batchCheck: (opt) =>
        ajax({
          url: '/sub-payment/check',
          method: 'POST',
          ...opt,
        }),
      // 查询未对账列表
      list: (opt) =>
        ajax({
          url: '/sub-payment/list',
          method: 'GET',
          ...opt,
        }),
    },
    // 未对账列表导出
    exportUnCompared: {
      upload: `${domainName + FinancialSys}/sub-payment/export-un-compared`,
      download: (opt) =>
        ajax({
          url: `/sub-payment/export-un-compared`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 导出模板下载
    downloadTemplate: {
      upload: `${domainName + FinancialSys}/bill-compare/download-template`,
      download: (opt) =>
        ajax({
          url: `/bill-compare/download-template`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 汇率模板下载
    exchangeRateDownload: {
      upload: `${domainName + FinancialSys}/basic-data/e-rate/download-template`,
      download: (opt) =>
        ajax({
          url: `/basic-data/e-rate/download-template`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    subPaymentexport: {
      upload: `${domainName + FinancialSys}/sub-payment/export`,
      download: (opt) =>
        ajax({
          url: `/sub-payment/export`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    //对账单明细批量处理结果导出
    detailExportDealResult: {
      upload: `${domainName + FinancialSys}/bill-compare/detail/export-deal-result`,
      download: (opt) =>
        ajax({
          url: `/bill-compare/detail/export-deal-result`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 导出对账明细
    billCompareExport: {
      upload: `${domainName + FinancialSys}/customer-bill-compare/detail/list/export`,
      download: (opt) =>
        ajax({
          url: `/customer-bill-compare/detail/list/export`,
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 付款
    payment: {
      //付款查询列表接口
      list: (opt) =>
        ajax({
          url: '/payment/list',
          method: 'GET',
          ...opt,
        }),
      //提交付款申请
      statementApply: (opt) => {
        return ajax({
          url: '/payment/statement-apply',
          method: 'POST',
          ...opt,
        })
      },
      //付款详情接口
      detailInfo: (opt) => {
        return ajax({
          url: '/payment/detail',
          method: 'GET',
          ...opt,
        })
      },
      // 付款退回
      applyRefund: (opt) => {
        return ajax({
          url: '/payment/apply-refund',
          method: 'POST',
          ...opt,
        })
      },
      //付款账户
      account: () => {
        return ajax({
          url: '/payment/payment-account-list',
          method: 'GET',
        })
      },
      //日志接口
      diary: (opt) => {
        return ajax({
          url: '/operation-log/list',
          method: 'get',
          ...opt,
        })
      },
      // 关联交易，付款申请
      paymentGLJY: (opt) => {
        return ajax({
          url: '/payment/settlement-apply',
          method: 'POST',
          ...opt,
        })
      },
      // 付款申请，实时保存明细接口
      save: (opt) => {
        return ajax({
          url: '/payment/item-real-time-save',
          method: 'POST',
          ...opt,
        })
      },
      //根据汇总单号查询付款明细
      subPaymentList: (opt) => {
        return ajax({
          url: '/payment/sub-payment-list',
          method: 'get',
          ...opt,
        })
      },
      // 根据汇总单号查询应付信息
      costItemList: (opt) => {
        return ajax({
          url: '/payment/cost-item-list',
          method: 'get',
          ...opt,
        })
      },
      // 勾选后发起付款申请/保存付款汇总单
      paymentSave: (opt) => {
        return ajax({
          url: '/payment/save',
          method: 'POST',
          ...opt,
        })
      },
      // 根据汇总单号查询基础信息+付款信息
      baseInfo: (opt) => {
        return ajax({
          url: '/payment/base-info',
          method: 'get',
          ...opt,
        })
      },
      // 发起付款申请操作页面-作废
      paymentInvalid: (opt) => {
        return ajax({
          url: '/payment/invalid',
          method: 'POST',
          ...opt,
        })
      },
      // 保存付款汇总单/提交付款申请
      paymentSubmitPayment: (opt) => {
        return ajax({
          url: '/payment/submit-payment',
          method: 'POST',
          ...opt,
        })
      },
      // 退款
      refund: (opt) => {
        return ajax({
          url: '/payment/refund',
          method: 'POST',
          ...opt,
        })
      },
      // 退款实时保存接口操作类型，1-修改金额，2-新增，3-删除
      refundRealTimeSave: (opt) => {
        return ajax({
          url: '/payment/refund/real-time-save',
          method: 'POST',
          ...opt,
        })
      },
      // 根据退款单号查询已核销的应付单
      refundVerificationList: (opt) => {
        return ajax({
          url: '/payment/refund/verification-list',
          method: 'GET',
          ...opt,
        })
      },
      // 核销，操作类型，1-修改金额，2-新增，3-删除
      refundVerification: (opt) => {
        return ajax({
          url: '/payment/refund/verification',
          method: 'POST',
          ...opt,
        })
      },
      refundTeamAndOrder: (opt) => {
        return ajax({
          url: '/payment/refund/team-and-order',
          method: 'POST',
          ...opt,
        })
      },
    },
    // 对账
    billCompare: {
      //对账单打印
      printOperate: (opt) => {
        return ajax({
          url: '/customer-bill-compare/print',
          method: 'POST',
          ...opt,
        })
      },
      // 对账单明细列表
      detailList: (opt) => {
        return ajax({
          url: '/customer-bill-compare/detail/list',
          method: 'get',
          ...opt,
        })
      },
      //删除对账单
      deleteOperate: (opt) => {
        return ajax({
          url: '/customer-bill-compare/delete',
          method: 'POST',
          ...opt,
        })
      },
      //删除对账单明细
      removeOperate: (opt) => {
        return ajax({
          url: '/customer-bill-compare/detail/delete',
          method: 'POST',
          ...opt,
        })
      },
      // 对账单明细操作
      detailOperate: (opt) => {
        return ajax({
          url: '/customer-bill-compare/detail/save',
          method: 'POST',
          ...opt,
        })
      },
      // 对账单操作
      operate: (opt) => {
        return ajax({
          url: '/bill-compare/operate',
          method: 'POST',
          ...opt,
        })
      },
      // 对账单列表
      list: (opt) => {
        return ajax({
          url: '/customer-bill-compare/list',
          method: 'get',
          ...opt,
        })
      },
      // 勾选后发起对账
      save: (opt) => {
        return ajax({
          url: '/customer-bill-compare/initiate',
          method: 'POST',
          ...opt,
        })
      },
      // 查询基础信息
      baseInfo: (opt) => {
        const { code, ...rest } = opt
        return ajax({
          url: `/customer-bill-compare/base-info/${code}`,
          method: 'GET',
          ...rest,
        })
      },
      // 保存/对账完成
      saveAndSubmit: (opt) => {
        return ajax({
          url: '/customer-bill-compare/save-and-submit',
          method: 'POST',
          ...opt,
        })
      },
      discard: (opt) => {
        return ajax({
          url: '/customer-bill-compare/discard',
          method: 'POST',
          ...opt,
        })
      },
      receiptList: (opt) => {
        return ajax({
          url: '/receipt-invoice/list',
          method: 'GET',
          ...opt,
        })
      },
      detailBatchDeal: (opt) =>
        ajax({
          url: '/bill-compare/detail/batch-deal',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          ...opt,
        }),
      // 对账收款撤销
      revokedReceiptByBillCompare: (opt) => {
        return ajax({
          url: `/receipt-by-bill-compare/cancel/${opt}`,
          method: 'get',
          ...opt,
        })
      },
      // 对账收款删除
      delReceiptByBillCompare: (opt) => {
        return ajax({
          url: `/receipt-by-bill-compare/del/${opt}`,
          method: 'get',
          ...opt,
        })
      },
      // 对账收款分页查询
      pageQuery: (opt) => {
        return ajax({
          url: '/receipt-by-bill-compare/page-query',
          method: 'post',
          ...opt,
        })
      },
    },
    // 付款审核
    subPayment: {
      //付款单审核列表
      list: (opt) => {
        return ajax({
          url: '/sub-payment/list-post',
          method: 'POST',
          ...opt,
        })
      },
      //付款单详情接口
      detailInfo: (opt) => {
        return ajax({
          url: `/sub-payment/${opt.subPaymentCode}`,
          method: 'GET',
          ...opt,
        })
      },
      // 付款审核通过/驳回
      audit: (opt) => {
        return ajax({
          url: '/sub-payment/audit',
          method: 'POST',
          ...opt,
        })
      },
      // 查询可核销列表
      verificationList: (opt) => {
        return ajax({
          url: '/sub-payment/verification-list',
          method: 'GET',
          ...opt,
        })
      },
    },
    receipt: {
      list: (opt) =>
        ajax({
          url: '/receipt-refund/list',
          method: 'GET',
          ...opt,
        }),

      // 收款详情
      receiptDetails: (opt) =>
        ajax({
          url: '/receipt-refund/receipt-detail',
          method: 'GET',
          ...opt,
        }),
      // 收款审核
      receiptCheck: (opt) =>
        ajax({
          url: '/receipt-refund/receipt-audit',
          method: 'POST',
          ...opt,
        }),
      // 收款批量审核
      receiptBatchCheck: (opt) =>
        ajax({
          url: '/receipt-refund/receipt-batch-audit',
          method: 'POST',
          ...opt,
        }),

      // 退款批量结转
      refundTransfer: (opt) =>
        ajax({
          url: '/receipt-refund/refund-transfer',
          method: 'POST',
          ...opt,
        }),
      // 退款查询-单个结转-直接重新结转共享
      singleRefundTransfer: (opt) =>
        ajax({
          url: `/receipt-refund/refund-again-transfer/${opt.collectCode}`,
          method: 'POST',
          ...opt,
        }),
      // 退款查询-单个结转-可选择重新结转方式
      singleRefundTransferWithChoice: (opt) =>
        ajax({
          url: '/receipt-refund/refund-again-transfer',
          method: 'POST',
          ...opt,
        }),
      // 收款查询-批量结转
      carryOver: (opt) =>
        ajax({
          url: '/receipt-refund/receipts-transfer',
          method: 'POST',
          ...opt,
        }),
      // 收款查询-单个结转
      singleTransfer: (opt) =>
        ajax({
          url: `/receipt-refund/receipts-again-transfer/${opt.collectCode}`,
          method: 'POST',
          ...opt,
        }),
      // 退款详情
      refundDetails: (opt) => {
        const { data, ...rest } = opt
        return ajax({
          url: `/receipt-refund/detail-all/${data.id}`,
          method: 'GET',
          ...rest,
        })
      },
      // 退款手续费修改
      refundFee: (opt) => {
        return ajax({
          url: '/receipt-refund/refund-fee',
          method: 'POST',
          ...opt,
        })
      },
      // 退款操作
      refundActions: (opt) => {
        const { data, ...rest } = opt
        return ajax({
          url: `/refund/${data.id}`,
          method: 'POST',
          ...rest,
        })
      },
      // 关联交易,手工收款
      offlineReceipt: (opt) => {
        return ajax({
          url: `/receipt-refund/related/offline-receipt`,
          method: 'POST',
          ...opt,
        })
      },
      // 可用预收款信息
      getPreReceiptsList: (opt) =>
        ajax({
          url: '/receipt-by-order/available-pre-receipts',
          method: 'post',
          ...opt,
        }),
      //汇总列表
      collectList: (opt) =>
        ajax({
          url: '/receipt-refund/collect-list',
          method: 'GET',
          ...opt,
        }),
      //收款汇总列表
      receiptsCollectList: (opt) =>
        ajax({
          url: '/receipt-refund/receipts-collect-list',
          method: 'GET',
          ...opt,
        }),
      // 订单转团调账 列表查询接口
      orderTeamTransferList: (opt) =>
        ajax({
          url: '/receipt-refund/list-transfer',
          method: 'GET',
          ...opt,
        }),
      // 订单转团调账 列表-结转/发起调账-提交
      orderTeamTransfer: (opt) =>
        ajax({
          url: '/receipt-refund/transfer-order-id',
          method: 'POST',
          ...opt,
        }),
      // 订单转团调账 发起调账数据查询接口
      orderTeamTransferDetail: (opt) =>
        ajax({
          url: `/receipt-refund/transfer-detail/${opt.orderId}`,
          method: 'GET',
          ...opt,
        }),
      // 订单转团调账 调账校验接口
      checkTransfer: (opt) =>
        ajax({
          url: '/receipt-refund/check-transfer',
          method: 'POST',
          ...opt,
        }),
      // 订单转团调账 调账详情页数据查询接口
      orderTeamTransferToDetail: (opt) =>
        ajax({
          url: `/receipt-refund/transfer-to-detail/${opt.orderId}`,
          method: 'GET',
          ...opt,
        }),
    },

    // 收款管理-代收代付
    collectionPayment: {
      // 查询代收代付列表数据
      list: (opt) =>
        ajax({
          url: '/collection-payment/list',
          method: 'GET',
          ...opt,
        }),
      // 客人收退款明细列表数据
      customerDetail: (opt) =>
        ajax({
          url: '/collection-payment/customer-detail',
          method: 'GET',
          ...opt,
        }),
      // 结算明细列表数据
      settlementDetail: (opt) =>
        ajax({
          url: '/collection-payment/settlement-detail',
          method: 'GET',
          ...opt,
        }),
      // 提取明细列表数据
      extractedDetail: (opt) =>
        ajax({
          url: '/collection-payment/extracted-detail',
          method: 'GET',
          ...opt,
        }),
      // 余额提取
      extracted: (opt) =>
        ajax({
          url: '/collection-payment/extracted',
          method: 'POST',
          ...opt,
        }),
      // 提取单详情
      getExtracted: (opt) =>
        ajax({
          url: `/collection-payment/extracted/${opt.extractedId}`,
          method: 'GET',
          ...opt,
        }),
    },
    // 开票管理
    invoice: {
      // 根据参数查询发票数据列表
      list: (opt) =>
        ajax({
          url: '/invoice/list',
          method: 'GET',
          ...opt,
        }),
      // 发票申请
      apply: (opt) =>
        ajax({
          url: '/invoice/apply',
          method: 'POST',
          ...opt,
        }),
      // 删除发票申请
      delete: (opt) =>
        ajax({
          url: '/invoice/delete',
          method: 'POST',
          ...opt,
        }),
      // 根据id号查询发票明细初始数据
      getDetailSaveList: (opt) =>
        ajax({
          url: '/invoice/detail-list',
          method: 'POST',
          ...opt,
        }),
      // 根据id查询发票数据
      getInvoiceData: (opt) =>
        ajax({
          url: '/invoice/apply-code',
          method: 'GET',
          ...opt,
        }),
      // 发票修改
      edit: (opt) =>
        ajax({
          url: '/invoice/edit',
          method: 'POST',
          ...opt,
        }),
      // 发票作废申请
      applyInvalid: (opt) =>
        ajax({
          url: '/invoice/apply-invalid',
          method: 'POST',
          ...opt,
        }),
      // 开票
      open: (opt) =>
        ajax({
          url: '/invoice/open-invoice',
          method: 'POST',
          ...opt,
        }),
      // 退回
      reject: (opt) =>
        ajax({
          url: '/invoice/reject',
          method: 'POST',
          ...opt,
        }),
      // 退回作废申请
      invalidReject: (opt) =>
        ajax({
          url: '/invoice/invalid-reject',
          method: 'POST',
          ...opt,
        }),
      // 作废
      invalid: (opt) =>
        ajax({
          url: '/invoice/invalid',
          method: 'POST',
          ...opt,
        }),
      // 推送缓冲区
      push: (opt) =>
        ajax({
          url: '/invoice/push',
          method: 'POST',
          ...opt,
        }),
      // 开票前校验
      check: (opt) =>
        ajax({
          url: '/invoice/check',
          method: 'POST',
          ...opt,
        }),
      // 校验销售公司是否支持电子发票
      isSupportElectron: (opt) =>
        ajax({
          url: '/invoice/is-support-electron',
          method: 'GET',
          ...opt,
        }),
      // 查询历史发票抬头信息，支持分页
      latestTitleInfo: (opt) =>
        ajax({
          url: '/invoice/latest-title-info',
          method: 'GET',
          ...opt,
        }),
    },
    downloadZip: {
      upload: `${domainName + FinancialSys}/download/zip`,
      download: (opt) =>
        ajax({
          url: `/download/zip`,
          method: 'POST',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
    },
    // 附件下载查询附件分类
    downloadClassify: (opt) =>
      ajax({
        url: '/download/classify',
        method: 'GET',
        ...opt,
      }),
    // 预借发票
    preformedInvoice: {
      // 预借发票列表
      list: (opt) =>
        ajax({
          url: '/preformed-invoice/list',
          method: 'POST',
          ...opt,
        }),
      // 预借发票审核列表
      auditList: (opt) =>
        ajax({
          url: '/invoice/audit-list',
          method: 'POST',
          ...opt,
        }),
      // 预借发票审核
      audit: (opt) =>
        ajax({
          url: '/invoice/audit',
          method: 'POST',
          ...opt,
        }),
      // 根据id预借发票明细初始数据
      getDetailSaveList: (opt) =>
        ajax({
          url: '/preformed-invoice/details-by-ids',
          method: 'POST',
          ...opt,
        }),
    },
    // 预收款开票
    preReceiptInvoice: {
      // 根据id号查询预收款发票明细初始数据
      getDetailSaveList: (opt) =>
        ajax({
          url: '/pre-receipt-invoice/detail-list',
          method: 'POST',
          ...opt,
        }),
    },
    //预收款管理
    preReceipt: {
      preReceiptDetail: (opt) =>
        ajax({
          url: `/pre-receipt/${opt.preReceiptCode}`,
          method: 'GET',
          ...opt,
        }),
      // 预收款使用列表
      useDetailList: (opt) =>
        ajax({
          url: '/pre-receipt/use-detail-list',
          method: 'GET',
          ...opt,
        }),

      // 预收款列表
      preReceiptList: (opt) =>
        ajax({
          url: '/pre-receipt/list',
          method: 'GET',
          ...opt,
        }),
      // 预收款删除
      preReceiptDelete: (opt) =>
        ajax({
          url: '/pre-receipt/delete',
          method: 'POST',
          ...opt,
        }),
      // 预收款退款
      preReceiptRefund: (opt) =>
        ajax({
          url: '/pre-receipt/refund',
          method: 'POST',
          ...opt,
        }),
      // 创建预收款
      preReceiptApply: (opt) =>
        ajax({
          url: '/pre-receipt/apply',
          method: 'POST',
          ...opt,
        }),
      // 编辑预收款
      preReceiptUpdate: (opt) =>
        ajax({
          url: '/pre-receipt/update',
          method: 'POST',
          ...opt,
        }),
      // 编辑预收款
      preReceiptTransfer: (opt) =>
        ajax({
          url: '/pre-receipt/transfer',
          method: 'POST',
          ...opt,
        }),
      // 退款校验
      refundCheck: (opt) =>
        ajax({
          url: '/pre-receipt/refund-check',
          method: 'POST',
          ...opt,
        }),
      // 转出校验
      transferCheck: (opt) =>
        ajax({
          url: '/pre-receipt/transfer-check',
          method: 'POST',
          ...opt,
        }),
    },
    // 预付管理
    prepaid: {
      //预付款审核接口，包含审核通过和审核驳回
      prepaidAudit: (opt) =>
        ajax({
          url: '/prepaid/audit',
          method: 'POST',
          ...opt,
        }),
      // 查询审核列表
      auditList: (opt) =>
        ajax({
          url: '/prepaid/audit-list',
          method: 'GET',
          ...opt,
        }),
      // 选择押金
      vendorRechargeList: (opt) =>
        ajax({
          url: '/prepaid/vendor-recharge-list',
          method: 'GET',
          ...opt,
        }),
      // 预付充值明细列表-转出申请
      applyTransfer: (opt) =>
        ajax({
          url: '/prepaid/apply-transfer',
          method: 'POST',
          ...opt,
        }),
      // 预付充值明细列表
      rechargeDetailList: (opt) =>
        ajax({
          url: '/prepaid/recharge-detail-list',
          method: 'GET',
          ...opt,
        }),

      // 根据参数查询预付款列表
      list: (opt) =>
        ajax({
          url: '/prepaid/list',
          method: 'GET',
          ...opt,
        }),
      // 预付交易明细列表(预付款使用列表)
      transactionDetail: (opt) =>
        ajax({
          url: '/prepaid/use-detail-list',
          method: 'GET',
          ...opt,
        }),
      // 预付充值明细列表
      rechargeDetail: (opt) =>
        ajax({
          url: '/prepaid/recharge-detail-list',
          method: 'GET',
          ...opt,
        }),
      // 预付退回明细列表
      backDetail: (opt) =>
        ajax({
          url: '/prepaid/refund-list',
          method: 'GET',
          ...opt,
        }),
      // 查询预付充值详情
      rechargeDetailInfo: (opt) =>
        ajax({
          url: `/prepaid/recharge-detail/${opt.c_rechargeCode}`,
          method: 'GET',
          ...opt,
        }),
      // 预付退回
      applyRefund: (opt) =>
        ajax({
          url: '/prepaid/apply-refund',
          method: 'POST',
          ...opt,
        }),
      // 预付充值
      recharge: (opt) =>
        ajax({
          url: '/prepaid/recharge',
          method: 'POST',
          ...opt,
        }),
      // 查询团信息分页列表
      queryTeamInfoPage: (opt) =>
        ajax({
          url: '/team/query-team-info-page',
          method: 'get',
          ...opt,
        }),
      // 获取预付款使用信息
      prepaidUseInfo: (opt) =>
        ajax({
          url: '/prepaid/prepaid-use-info',
          method: 'get',
          ...opt,
        }),
      // 重新结转
      transfer: (opt) =>
        ajax({
          url: '/prepaid/transfer',
          method: 'POST',
          ...opt,
        }),
      // 作废
      invalid: (opt) =>
        ajax({
          url: '/prepaid/invalid',
          method: 'POST',
          ...opt,
        }),
    },
    // 收票管理
    receipts: {
      list: (opt) =>
        ajax({
          /*列表接口 */
          url: '/cost-invoice/list',
          method: 'get',
          ...opt,
        }),
      submit: (opt) =>
        ajax({
          /*提交成本票录入 */
          url: '/cost-invoice/save',
          method: 'post',
          ...opt,
        }),
      detailshow: (costInvoiceCode) => {
        return ajax({
          /*提交成本票录入 */
          url: `/cost-invoice/${costInvoiceCode}`,
          method: 'get',
        })
      },
      relativelist: (opt) =>
        ajax({
          /*搜索关联 (还未选中) */
          url: '/cost-invoice/payment-list',
          method: 'post',
          ...opt,
        }),
      reportBill: (opt) =>
        ajax({
          /*成本票报账 */
          url: '/cost-invoice/reimburse',
          method: 'post',
          ...opt,
        }),
    },
    // 关联交易付款申请查询接口
    paymentQueryGLJY: (opt) =>
      ajax({
        url: '/sub-payment/classify-item',
        method: 'POST',
        ...opt,
      }),
    // 基础配置
    config: {
      sapDepartment: {
        list: (opt) =>
          ajax({
            url: '/basic-data/department-relation/list',
            method: 'GET',
            ...opt,
          }),
        save: (opt) =>
          ajax({
            url: '/basic-data/save-company-department-relation',
            method: 'POST',
            ...opt,
          }),
        delete: (id) =>
          ajax({
            url: `/basic-data/delete-company-department-relation/${id}`,
            method: 'DELETE',
          }),
      },
      taxRate: {
        // 销项税
        output: {
          // 查询销项税列表
          list: (opt) =>
            ajax({
              url: '/basic-data/output-tax-rate-config/list',
              method: 'GET',
              ...opt,
            }),
          // 保存销项税配置
          save: (opt) =>
            ajax({
              url: '/basic-data/output-tax-rate-config/save',
              method: 'POST',
              ...opt,
            }),
          // 删除销项税配置
          delete: (id) =>
            ajax({
              url: `/basic-data/output-tax-rate-config/delete/${id}`,
              method: 'DELETE',
            }),
        },
        // 进项税
        input: {
          // 查询进项税列表
          list: (opt) =>
            ajax({
              url: '/basic-data/input-tax-rate-config/list',
              method: 'GET',
              ...opt,
            }),
          // 保存进项税配置
          save: (opt) =>
            ajax({
              url: '/basic-data/input-tax-rate-config/save',
              method: 'POST',
              ...opt,
            }),
          // 删除进项税配置
          delete: (id) =>
            ajax({
              url: `/basic-data/input-tax-rate-config/delete/${id}`,
              method: 'DELETE',
            }),
        },
      },
      incomeCostItem: {
        cost: {
          list: (opt) =>
            ajax({
              url: '/basic-data/cost-item/list',
              method: 'POST',
              ...opt,
            }),
          save: (opt) =>
            ajax({
              url: '/basic-data/cost-item/save',
              method: 'POST',
              ...opt,
            }),
          delete: (id) =>
            ajax({
              url: `/basic-data/cost-item/delete/${id}`,
              method: 'DELETE',
            }),
        },
        income: {
          list: (opt) =>
            ajax({
              url: '/basic-data/income-item/list',
              method: 'POST',
              ...opt,
            }),
          save: (opt) =>
            ajax({
              url: '/basic-data/income-item/save',
              method: 'POST',
              ...opt,
            }),
          delete: (id) =>
            ajax({
              url: `/basic-data/income-item/delete/${id}`,
              method: 'DELETE',
            }),
        },
      },
      itemType: {
        list: (opt) =>
          ajax({
            url: '/basic-data/item-config/list',
            method: 'GET',
            ...opt,
          }),
        save: (opt) =>
          ajax({
            url: '/basic-data/item-config/save',
            method: 'POST',
            ...opt,
          }),
        delete: (id) =>
          ajax({
            url: `/basic-data/item-config/delete/${id}`,
            method: 'DELETE',
          }),
      },
      resourceCostDefaultConfig: {
        list: (opt) =>
          ajax({
            url: '/basic-data/resource-cost-default-config/list',
            method: 'GET',
            ...opt,
          }),
        save: (opt) =>
          ajax({
            url: '/basic-data/resource-cost-default-config/save',
            method: 'POST',
            ...opt,
          }),
        delete: (id) =>
          ajax({
            url: `/basic-data/resource-cost-default-config/delete/${id}`,
            method: 'DELETE',
          }),
      },
      incomeCostSubject: {
        cost: {
          list: (opt) =>
            ajax({
              url: '/basic-data/cost-subject/list',
              method: 'POST',
              ...opt,
            }),
          save: (opt) =>
            ajax({
              url: '/basic-data/cost-subject/save',
              method: 'POST',
              ...opt,
            }),
          delete: (id) =>
            ajax({
              url: `/basic-data/cost-subject/delete/${id}`,
              method: 'DELETE',
            }),
        },
        income: {
          list: (opt) =>
            ajax({
              url: '/basic-data/income-subject/list',
              method: 'POST',
              ...opt,
            }),
          save: (opt) =>
            ajax({
              url: '/basic-data/income-subject/save',
              method: 'POST',
              ...opt,
            }),
          delete: (id) =>
            ajax({
              url: `/basic-data/income-subject/delete/${id}`,
              method: 'DELETE',
            }),
        },
      },
      teamType: {
        list: (opt) =>
          ajax({
            url: '/basic-data/team-type-config/list',
            method: 'GET',
            ...opt,
          }),
        save: (opt) =>
          ajax({
            url: '/basic-data/team-type-config/save',
            method: 'POST',
            ...opt,
          }),
        delete: (id) =>
          ajax({
            url: `/basic-data/team-type-config/delete/${id}`,
            method: 'DELETE',
          }),
      },
      invoiceItemConfig: {
        // 查询发票项目配置列表
        list: (opt) =>
          ajax({
            url: '/basic-data/invoice-item-config/list',
            method: 'GET',
            ...opt,
          }),
        // 新增发票项目配置
        add: (opt) =>
          ajax({
            url: '/basic-data/invoice-item-config/add',
            method: 'POST',
            ...opt,
          }),
        // 更新发票项目配置
        update: (opt) =>
          ajax({
            url: '/basic-data/invoice-item-config/update',
            method: 'POST',
            ...opt,
          }),
        // 查询配置的所有开票项目和编码(发票项目配置列表-开票项目)
        invoiceItemList: (opt) =>
          ajax({
            url: '/basic-data/invoice-item/list',
            method: 'GET',
            ...opt,
          }),

        // 查询发票属性配置列表
        invoiceTerminalConfigList: (opt) =>
          ajax({
            url: '/basic-data/invoice-terminal-config-list',
            method: 'GET',
            ...opt,
          }),
        // 查询发票终端系统列表
        invoiceTerminalList: (opt) =>
          ajax({
            url: '/basic-data/invoice-terminal-list',
            method: 'GET',
            ...opt,
          }),
        // 保存发票属性信息
        save: (opt) =>
          ajax({
            url: '/basic-data/invoice-terminal-config/save',
            method: 'POST',
            ...opt,
          }),
        // 保存开票项目和编码
        invoiceItemSave: (opt) =>
          ajax({
            url: '/basic-data/invoice-item/save',
            method: 'POST',
            ...opt,
          }),
      },
      // 币种
      currency: {
        // 查询币种列表
        detailList: (opt) =>
          ajax({
            url: '/basic-data/currency/detail-list',
            method: 'GET',
            ...opt,
          }),
        // 保存币种
        save: (opt) =>
          ajax({
            url: '/basic-data/currency/save',
            method: 'POST',
            ...opt,
          }),
        delete: (id) =>
          ajax({
            url: `/basic-data/currency/delete/${id}`,
            method: 'DELETE',
          }),
        // 校验是否存在币种
        exist: (opt) =>
          ajax({
            url: '/basic-data/currency/exist',
            method: 'GET',
            ...opt,
          }),
        update: (opt) =>
          ajax({
            url: '/basic-data/currency/update',
            method: 'POST',
            ...opt,
          }),
      },
      // 汇率
      exchangeRate: {
        // 查询汇率列表
        list: (opt) =>
          ajax({
            url: '/basic-data/e-rate/page',
            method: 'GET',
            ...opt,
          }),
        // 查询币种筛选框-枚举列表
        simpleList: (opt) =>
          ajax({
            url: '/basic-data/currency/simple-list',
            method: 'GET',
            ...opt,
          }),
        // 维护汇率
        save: (opt) =>
          ajax({
            url: '/basic-data/e-rate/save',
            method: 'POST',
            ...opt,
          }),
        // 更新汇率
        update: (opt) =>
          ajax({
            url: '/basic-data/e-rate/update',
            method: 'POST',
            ...opt,
          }),
        // 导入汇率
        import: (opt) =>
          ajax({
            url: '/basic-data/e-rate/import',
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            ...opt,
          }),
      },
    },
    // 审批流
    bpm: {
      // 流程信息列表
      processList: (opt) =>
        ajax({
          url: '/bpm/process/list',
          method: 'GET',
          ...opt,
        }),
      // 流程用户任务列表
      userTaskList: (opt) =>
        ajax({
          url: '/bpm/process/user-task-list',
          method: 'GET',
          ...opt,
        }),
      // 流程路由规则参数元数据列表
      paramMetadataList: (opt) =>
        ajax({
          url: '/bpm/process/route-rule-param-metadata/list',
          method: 'GET',
          ...opt,
        }),
      // 流程路由规则计算公式列表
      formulaList: (opt) =>
        ajax({
          url: '/bpm/process/route-rule-formula/list',
          method: 'GET',
          ...opt,
        }),
      routeRule: {
        // 流程路由规则列表
        list: (opt) =>
          ajax({
            url: '/bpm/process/route-rule/list',
            method: 'GET',
            ...opt,
          }),
        // 流程路由规则删除
        delete: (opt) =>
          ajax({
            url: '/bpm/process/route-rule/delete',
            method: 'DELETE',
            ...opt,
          }),
        // 流程路由规则保存
        save: (opt) =>
          ajax({
            url: '/bpm/process/route-rule/save',
            method: 'POST',
            ...opt,
          }),
        // 流程路由规则详情
        detail: (opt) =>
          ajax({
            url: '/bpm/process/route-rule/detail',
            method: 'GET',
            ...opt,
          }),
      },
      routeRuleParam: {
        // 流程路由规则参数列表
        list: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param/list',
            method: 'GET',
            ...opt,
          }),
        // 流程路由规则参数保存
        save: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param/save',
            method: 'POST',
            ...opt,
          }),
        // 流程路由规则参数删除
        delete: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param/delete',
            method: 'DELETE',
            ...opt,
          }),
      },
      routeRuleParamValue: {
        // 流程路由规则参数值列表
        list: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param-value/list',
            method: 'GET',
            ...opt,
          }),
        // 流程路由规则参数值保存
        save: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param-value/save',
            method: 'POST',
            ...opt,
          }),
        // 流程路由规则参数值删除
        delete: (opt) =>
          ajax({
            url: '/bpm/process/route-rule-param-value/delete',
            method: 'DELETE',
            ...opt,
          }),
      },
    },
  }
}
